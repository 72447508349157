/// <reference path="o365.pwa.declaration.sw.apiRequestOptions.ApiFileRequestOptions.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as ApiFileRequestOptionsModule from 'o365.pwa.declaration.sw.apiRequestOptions.ApiFileRequestOptions.d.ts'

import type { ApiFileRoute } from 'o365.pwa.declaration.sw.strategies.api.file.strategy.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiRequestOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiRequestOptions.ts");
    const { getAppIDFromURL } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.GetAppIDFromUrl.d.ts')>("o365.pwa.modules.sw.GetAppIDFromUrl.ts");

    class ApiFileRequestOptions<T extends ApiFileRequestOptionsModule.IApiFileRequestStatic> implements ApiFileRequestOptionsModule.ApiFileRequestOptions<T> {
        public readonly appId: string;
        public readonly options: T;
        public readonly headers: Map<string, Set<string>>;


        constructor(options: ApiFileRequestOptionsModule.IApiFileRequestOptions<T>) {
            this.appId = options.appId;
            this.options = options.options;
            this.headers = options.headers;
        }

        static async fromRequest<T extends ApiFileRequestOptionsModule.IApiFileRequestStatic>(request: Request, apiFileRoute: ApiFileRoute): Promise<InstanceType<typeof ApiRequestOptions<ApiFileRequestOptionsModule.IApiFileRequestOptions<T>>>> {
            const clonedRequest = request.clone();
            const appId = getAppIDFromURL(new URL(request.referrer));

            let options;
            switch (apiFileRoute) {
                case 'VIEW':
                    options = this.getUrlParams(request.url, apiFileRoute);
                    break;
                case 'UPLOAD': {
                    try {
                        const formData: FormData | null = await clonedRequest.formData().catch(() => null);

                        let data = {};
                        if (formData) {
                            data = this.parseFormData(formData);
                        }

                        options = { ...data, ...this.getUrlParams(request.url, apiFileRoute) };
                    } catch (error) {
                        console.error("Error processing form data:", error);
                        options = { ...this.getUrlParams(request.url, apiFileRoute) };
                    }
                    break;
                }
                case 'OLD-CHUNK-UPLOAD': {
                    const formData: FormData = await clonedRequest.formData();
                    const data = this.parseFormData(formData);
                    options = { ...data, ...this.getUrlParams(request.url, apiFileRoute) };
                    break;
                }
                case 'CHUNK-UPLOAD': {
                    const formData: FormData = await clonedRequest.formData();
                    const data = this.parseFormData(formData);
                    options = { ...data, ...this.getUrlParams(request.url, apiFileRoute) };
                    break;
                }
                case 'CHUNK-INITIATE': {
                    const requestData = clonedRequest;
                    const data = await this.getRequestData(requestData);
                    options = { providedRecord: data, ...this.getUrlParams(request.url, apiFileRoute) };
                    break;
                }
                case 'CHUNK-SETCRC': {
                    const requestData = await clonedRequest.json();
                    options = {...requestData};
                    break;
                }
                case 'DOWNLOAD':
                    options = this.getUrlParams(request.url, apiFileRoute);
                    break;
                case 'PDF-DOWNLOAD':
                    options = this.getUrlParams(request.url, apiFileRoute);
                    break;
                case 'PDF-VIEW':
                    options = this.getUrlParams(request.url, apiFileRoute);
                    break;
            }

            const headers = new Map<string, Set<string>>();

            request.headers.forEach((value, key, _parent) => {
                if (headers.has(key) === false) {
                    headers.set(key, new Set<string>());
                }

                const values = value.split(',').map((value) => value.trim());

                values.forEach((value) => {
                    headers.get(key)!.add(value);
                });
            });

            return new ApiRequestOptions(request, new ApiFileRequestOptions<T>({
                appId,
                options,
                headers
            }));
        }
        static parseFormData(formData: FormData) {
            let data = {} as any;
            data.extraValues = {} as any;
            // parseFormData
            for (let [key, value] of formData.entries()) {
                if (key === "File") {
                    const file = value as File;
                    data["data"] = this.fileToBlob(value as File);
                    data["filename"] = file.name;
                    data["filesize"] = file.size;
                    data["extension"] = this.getFileExtension(file.name);
                    data["mimeType"] = file.type;
                } else if (key === "data") {
                    data[key] = value;
                } else if (key === "filename") {
                    data[key] = value;
                    data["extension"] = this.getFileExtension(value as string);
                } else {
                    data.extraValues[key] = value;
                }
            }
            if (!data.data) throw new Error("File data was not recieved as expected.");
            if (data["extension"]) data["mimeType"] = self.mime.getType(data["extension"]);
            return data;
        }

        static fileToBlob(file: File): Blob {
            return new Blob([file], { type: file.type });
        }

        static getFileExtension(name: string): string | null {
            const lastIndex = name.lastIndexOf(".");

            if (lastIndex < 0) return null; // No extension found

            return name.slice(lastIndex + 1);
        }

        static getMimeTypeFromExtension(extension: string): string | undefined {
            // Define a dictionary of file extensions and their corresponding MIME types
            const mimeTypes: { [key: string]: string } = {
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'png': 'image/png',
                'gif': 'image/gif',
                'pdf': 'application/pdf',
                'txt': 'text/plain',
                'html': 'text/html',
                'css': 'text/css',
                'js': 'application/javascript',
                'json': 'application/json',
                // Add more extensions and MIME types as needed
            };

            // Convert the extension to lowercase and handle cases with or without a leading dot
            const ext = extension.toLowerCase();

            // Retrieve the MIME type from the dictionary or return undefined if not found
            return mimeTypes[ext];
        }

        static getUrlParams(url: string, apiFileRoute: 'VIEW'): ApiFileRequestOptionsModule.IUrlView;
        static getUrlParams(url: string, apiFileRoute: 'DOWNLOAD'): ApiFileRequestOptionsModule.IUrlDownload;
        static getUrlParams(url: string, apiFileRoute: 'UPLOAD'): ApiFileRequestOptionsModule.IUrlUpload;
        static getUrlParams(url: string, apiFileRoute: 'OLD-CHUNK-UPLOAD'): ApiFileRequestOptionsModule.IUrlOldChunkUpload;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-UPLOAD'): ApiFileRequestOptionsModule.IUrlChunkUpload;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-INITIATE'): ApiFileRequestOptionsModule.IUrlChunkInitiate;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-SETCRC'): ApiFileRequestOptionsModule.IUrlChunkSetCRC;
        static getUrlParams(url: string, apiFileRoute: 'PDF-VIEW'): ApiFileRequestOptionsModule.IUrlPdfView;
        static getUrlParams(url: string, apiFileRoute: 'PDF-DOWNLOAD'): ApiFileRequestOptionsModule.IUrlPdfDownload;
        static getUrlParams(url: string, apiFileRoute: ApiFileRoute): ApiFileRequestOptionsModule.IUrlParams {
            // TODO: Add support for retrieving file from the query string instead of the path. Both needs to be supported, but the querystring takes priority
            switch (apiFileRoute) {
                case 'VIEW': {
                    const viewParams = ApiFileRequestOptions.getUrlParameters(url, /file\/view\/(.*?)\/(.*?)\/(.*?)\/(.*?)(?:\/|$)/);
                    if (!viewParams) throw new Error("Invalid VIEW URL format.");
                    const [, appId, databaseId, objectStoreId, primKey, fileName] = viewParams;
                    return {
                        option: "view",
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey,
                        fileName
                    } as ApiFileRequestOptionsModule.IUrlView;
                }
                case 'DOWNLOAD': {
                    const downloadParams = ApiFileRequestOptions.getUrlParameters(url, /file\/download\/(.*?)\/(.*?)\/(.*?)\/(.*?)(?:\/|$)/);
                    if (!downloadParams) throw new Error("Invalid DOWNLOAD URL format.");
                    const [, appId, databaseId, objectStoreId, primKey, fileName] = downloadParams;
                    return {
                        option: "download",
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey,
                        fileName
                    } as ApiFileRequestOptionsModule.IUrlDownload;
                }
                case 'UPLOAD': {
                    const uploadParams = ApiFileRequestOptions.getUrlParameters(url, /file\/upload\/(.*?)\/(.*?)\/(.*?)\/(.*?)(?:\/|$)/);
                    if (!uploadParams) throw new Error("Invalid UPLOAD URL format.");
                    const [, appId, databaseId, objectStoreId, primKey] = uploadParams;
                    return {
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey: primKey === "undefined" ? undefined : primKey,
                    } as ApiFileRequestOptionsModule.IUrlUpload;
                }
                case 'CHUNK-UPLOAD': {
                    const chunkUploadParams = ApiFileRequestOptions.getUrlParameters(url, /file\/chunkupload\/upload\/([^\/]+)(?:\/|$)/);
                    if (!chunkUploadParams) throw new Error("Invalid CHUNK-UPLOAD URL format.");
                    const [, uploadRef] = chunkUploadParams;
                    return {
                        uploadRef: uploadRef === "undefined" ? undefined : uploadRef,
                    } as ApiFileRequestOptionsModule.IUrlChunkUpload;
                }
                case 'CHUNK-INITIATE': {
                    const chunkUploadParams = ApiFileRequestOptions.getUrlParameters(
                        url,
                        /file\/chunkupload\/initiate\/(.*?)\/(.*?)\/(.*?)(?:\/(.*?))?(?:\/|$)/
                    );
                    if (!chunkUploadParams) throw new Error("Invalid CHUNK-UPLOAD URL format.");

                    const [, appId, databaseId, objectStoreId, primKey] = chunkUploadParams;

                    return {
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey: primKey === "undefined" ? undefined : primKey,
                    } as ApiFileRequestOptionsModule.IUrlChunkInitiate;
                }

                case 'CHUNK-SETCRC': {
                    const chunkUploadParams = ApiFileRequestOptions.getUrlParameters(url, /.*\/file\/chunkupload\/setcrc32\/([^\/]+)(?:\/|$)/);
                    if (!chunkUploadParams) throw new Error("Invalid CHUNK-UPLOAD URL format.");
                    const [, uploadRef] = chunkUploadParams;
                    return {
                        uploadRef: uploadRef === "undefined" ? undefined : uploadRef,
                    } as ApiFileRequestOptionsModule.IUrlChunkSetCRC;
                }
                case 'PDF-VIEW': {
                    const pdfViewParams = ApiFileRequestOptions.getUrlParameters(url, /view-pdf\/(.*?)\/(.*?)\/(.*?)\/(.*?)\/(.*?)(?:\/|$)/);
                    if (!pdfViewParams) throw new Error("Invalid PDF-VIEW URL format.");
                    const [, appId, databaseId, objectStoreId, primKey, fileName] = pdfViewParams;
                    return {
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey,
                        fileName
                    } as ApiFileRequestOptionsModule.IUrlPdfView;
                }
                case 'PDF-DOWNLOAD': {
                    const pdfDownloadParams = ApiFileRequestOptions.getUrlParameters(url, /download-pdf\/(.*?)\/(.*?)\/(.*?)\/(.*?)\/(.*?)(?:\/|$)/);
                    if (!pdfDownloadParams) throw new Error("Invalid PDF-DOWNLOAD URL format.");
                    const [, appId, databaseId, objectStoreId, primKey, fileName] = pdfDownloadParams;
                    return {
                        appId,
                        databaseId,
                        objectStoreId,
                        primKey,
                        fileName
                    } as ApiFileRequestOptionsModule.IUrlPdfDownload;
                }
                default:
                    throw new Error('Invalid Api File Route');
            }
        };

        static getUrlParameters(url: string, regex: RegExp): RegExpMatchArray | null {
            const match = url.match(regex);
            return match;
        }

        static async getFile(request: Request): Promise<Blob | null> {
            const reader = request?.body?.getReader();
            const file = await reader?.read();

            if (file && file.done === false) {
                const blob = new Blob([file.value], { type: 'application/octet-stream' });
                return blob;

            }

            return null;
        }

        static async getRequestData(request: Request) {
            const body = await request.json();
            if (!body) {
                throw Error("Could not read request body.")
            }
            let data: Record<string, any> = {
                Updated: new Date().toISOString(),
                FileName: body?.FileName,
                FileSize: body?.FileSize,
                FileRef: self.crypto.randomUUID(),
                PrimKey: self.crypto.randomUUID()
            }

            if (body.values) {
                for (const [key, value] of Object.entries(body.values)) {
                    data[key] = value;
                }
            }
            return data;
        }
    }

    self.o365.exportScripts({ ApiFileRequestOptions });

})();
